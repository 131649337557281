<template>
  <div>
    <v-btn class="primary" to="{ name: 'home' }" style="margin: 16px 0 0 10px">Back</v-btn>

    <v-container>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
      <!-- <v-app id="inspire"> -->
      <v-form class="sober" v-model="valid">
        <v-row>
          <v-col cols="12" sm="6" md="7">
            <v-row>
              <v-col cols="4"> आर्थिक बर्ष <span>*</span> </v-col>
              <v-col class="d-flex" cols="8">
                <v-select v-model="registration.fiscal_year_id" :rules="[required('Fiscal Year')]" :items="fiscalyear" item-value="id" item-text="f_year" :item-key="fiscalyear"
                  dense></v-select>
              </v-col>
            </v-row>

            <v-row v-if="user_role_id == 8">
              <v-col cols="4"> फारम पेश मिति <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.submitted_date_np" :rules="[required('Registration Date')]" label="" class="" clearable></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="4"> फारम दर्ता मिति <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.registration_date_np" :rules="[required('Registration Date')]" label="" class="" clearable></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4"> निर्माणको विवरण <span>*</span> </v-col>
              <v-col cols="8">
                <v-select v-model="registration.construction_type_id" :rules="[required('Construction Type')]" :items="constructiontype" item-value="construction_type_id"
                  item-text="construction_type_name" :item-key="constructiontype" dense></v-select>
              </v-col>
            </v-row>

            <v-row v-if="registration.construction_type_id == 5 ||
              registration.construction_type_id == 8 ||
              registration.construction_type_id == 9
              ">
              <v-dialog v-model="dialogSelect" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-col cols="4">
                    <v-btn color="primary" dark x-small v-bind="attrs" v-on="on" small rounded>
                      <v-icon left> mdi-pencil </v-icon>
                      Select File
                    </v-btn>
                  </v-col>
                  <v-col cols="4" class="mt-2">
                    {{ selectedReg.houseowner_name_np }}
                  </v-col>
                </template>
                <v-card class="elevation-12 my-2">
                  <v-toolbar color="primary" dense dark flat>
                    <v-toolbar-title> Select File </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogSelect = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-data-table :headers="headers" :items="items" :search="search" :items-per-page="10" :hide-default-header="false" :loading="isLoading" item-key="name"
                    class="sober elevation-12">
                    <template v-slot:body.prepend>
                      <tr>
                        <td>
                          <v-text-field label="Search" type="text" v-model="searchKeys.houseowner_name_np" @keyup="searchFilters" @click="searchFilters"></v-text-field>
                        </td>
                        <td>
                          <v-text-field label="Search" type="text" v-model="searchKeys.land_plot_no" @keyup="searchFilters" @click="searchFilters">></v-text-field>
                        </td>
                        <td>
                          <v-text-field label="Search" type="text" v-model="searchKeys.file_code" @keyup="searchFilters" @click="searchFilters">></v-text-field>
                        </td>
                        <td colspan="2"></td>
                      </tr>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-btn color="primary" small v-on:click="selectRegistration(item)">Select
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogKittaKaatNaamsari" max-width="290">
                <v-card>
                  <v-card-title class="text-h5"> कित्ताकाट भएको छ कि छैन ?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="red accent-3" @click="clickKittaDialog(false)"> छैन </v-btn>
                    <v-btn color="success" @click="clickKittaDialog(true)"> छ </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>

            <!-- <v-row v-if="registration.construction_type_id == 8">
              <v-col cols="4"> कित्ताकाट भएको छ कि छैन ? <span>*</span> </v-col>
              <v-radio-group v-model="row" row>
                <v-radio label="छ" value="1"></v-radio>
                <v-radio label="छैन" value="0"></v-radio>
              </v-radio-group>
            </v-row> -->

            <v-row v-if="registration.construction_type_id == 8">
              <v-col cols="12">
                <h3 class="text-center text-decoration-underline">नयाँ घरधनिको विवरण</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Houseowner name <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.houseowner_name" :rules="[required('Houseowner Name')]" label="" dense></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4"> घरधनीको नाम <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.houseowner_name_np" :rules="requiredUnicode('घरधनीको नााम')" label="" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> वडा नं <span>*</span> </v-col>
              <v-col cols="8">
                <v-select :items="wards" v-model="registration.ward_no" :rules="requiredUnicodeNumber('वडा नं')" label="" dense></v-select>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col cols="4"> आन्तरिक संकेत न <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.internal_code" :rules="[required('Internal Code')]" label="" dense>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4"> सम्पर्क नं <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.contact_no" :rules="requiredPhone('Contact No.')" label="" dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> ठेगाना <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.location" :rules="requiredUnicode('ठेगाना')" label="" dense required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> सडक नाम/ चाैडाई </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.street_no" dense required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> जग्गा कित्ता न <span>*</span> </v-col>

              <v-col cols="8">
                <v-text-field v-model="registration.land_plot_no" :rules="requiredUnicode('जग्गा कित्ता न')" label="" dense required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4"> करदाता संकेत न <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.houseowner_tax_no" :rules="requiredUnicode('करदाता संकेत न')" label="" dense required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Longitude <span>*</span> </v-col>
              <v-col cols="8">
                <v-text-field v-model="registration.longitude" :rules="[required('Longitude')]" label="" dense required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Latitude <span>*</span> </v-col>

              <v-col cols="8">
                <v-text-field v-model="registration.latitude" :rules="[required('Latitude')]" label="" dense required>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="5" sm="12">
            <v-simple-table height="550px">
              <template v-slot:default>
                <!-- <thead>
                  <tr>
                    <th class="text-center">Require Documents</th>
                  </tr>
                </thead> -->
                <v-toolbar color="primary darken-5" dark flat>
                  <v-toolbar-title class="flex text-center">आवश्यक कागजातहरु </v-toolbar-title>
                </v-toolbar>
                <tbody>
                  <tr v-for="item in require_documents" :key="item.name">
                    <td>{{ item.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12" md="12" sm="12">
            <div class="map">
              <l-map :zoom="zoom" :center="center" @click="add">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :lat-lng="marker" :icon="defaultIcon"></l-marker>
                <l-geo-json :geojson="geojson" :options="options"></l-geo-json>
              </l-map>
            </div>
          </v-col>

          <v-col cols="12" md="12" sm="12">
            <v-btn primary v-on:click="save" class="primary mb-12" :disabled="!valid">Submit</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- <v-btn primary v-on:click="alertError">test</v-btn> -->
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :multi-line="mode === 'multi-line'" :timeout="3000">
        {{ successfulMessage }}
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LGeoJson } from "vue2-leaflet";
import { engNumConvert } from "../mixins/numericToUnicode.js";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
  },
  name: "Login",
  mixins: [engNumConvert],
  data() {
    const myMarkerIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
      iconSize: [25, 41], // size of the icon
      shadowSize: [25, 41], // size of the shadow
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [9, 41], // the same for the shadow
      popupAnchor: [-3, -76], // point from which the po
    });
    return {
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.successfulMessage,
        color: "error",
      },
      dialogSelect: false,
      dialogKittaKaatNaamsari: false,
      radios: null,
      require_documents: [
        {
          name: "१. जग्गा धनी प्रमाणपत्र प्रतिलिपि",
        },
        {
          name: "२. चालु आर्थिक बर्षको मालपोत तिरेको रसिदको प्रतिलिपि",
        },
        {
          name: "३. नागरिकता प्रमाणपत्रको प्रतिलिपि",
        },
        {
          name: "४. कि.नं.स्पष्ट भएको नापी प्रमाणित नक्शा (ब्लु प्रिन्ट)",
        },
        {
          name: "५. डिजाईनरको अनुमतिपत्रको नबिकरण सहितको फोटोकपी (सरोकारवालाबाट प्रमाणित)",
        },
        {
          name: "६. मञ्जुरी लिई बनाउने भएमा नक्शा वालाले कानुन शाखाको रोहवरमा भएको मञ्जुरीनामा",
        },
        {
          name: "७. वारेश राखि नक्शा पास गर्ने भए वारिसको प्रमाणितको प्रतिलिपि",
        },
        {
          name: "८. नक्सा बनाउने वा भवन डिजाईन गर्ने प्राविधिकद्धारा मन्जुरी पत्र",
        },
        {
          name: "९. भवनको नक्सा",
        },
        {
          name: "१०. घरधनिको फोटो",
        },
      ],
      isLoading: false,
      valid: false,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      // requiredNepali(propertyType){
      // console.log(v);
      // if (/^\W$/.test(v)){
      //   return v => !!v || `${propertyType} should be written in Nepali Unicode`;
      // }
      // },

      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },

      requiredUnicode(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\W$/.test(v) || 'nice one'
          (v) => /^[\W]+$/.test(v) || `${propertyType} नेपाली युनिकोडमा लेख्नुहोला ।`,
        ];
      },

      requiredPhone(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\w$/ || 'Please write in english format',
          (v) =>
            /^\+?([९७७,977])?[०-९,0-9]{9,15}$/.test(v) ||
            `${propertyType} is not valid phone number`,
        ];
      },

      requiredUnicodeNumber(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => /^[०-९ 0-9]{1,3}$/.test(v) || "वडाको नम्बर मात्र लेख्नुहोला।",
        ];
      },

      fiscalyear: [],
      constructiontype: [],
      FiscalYearValidationMessage: false,
      DateValidationMessage: false,
      constructionTypeValidationMessage: false,
      houseownernameValidationMessage: false,
      houseownernameNpValidationMessage: false,
      wardNoValidationMessage: false,
      internalCodeValidationMessage: false,
      contactNoValidationMessage: false,
      locationValidationMessage: false,
      streetNoValidationMessage: false,
      landPlotNoValidationMessage: false,
      houseownerTaxNoValidationMessage: false,
      longitudeValidationMessage: false,
      latitudeValidationMessage: false,
      registration: {
        old_reg_id: 0,
        fiscal_year_id: "",
        fiscal_year: "2078-1-31",
        construction_type_id: "",
        registration_date_np: "",
        submitted_date_np: "",
        houseowner_name: "",
        houseowner_name_np: "",
        houseowner_tax_no: "",
        ward_no: "",
        internal_code: "-",
        contact_no: "",
        location: "",
        street_no: "",
        land_plot_no: "",
        latitude: "",
        longitude: "",
        token: "",
        message: "",
        registration_date: "2014-3-4",
        landowner_certificate_submitted: true,
        revenue_tax_slip_submitted: true,
        landowner_registration_certificate_submitted: true,
        citizenship_submitted: true,
        blueprint_submitted: true,
        designer_certificate_submitted: true,
        authority_letter_submitted: true,
        nominee_certificate_submitted: true,
        designer_authority_letter_submitted: true,
        house_map_submitted: true,
        houseowner_photo_submitted: true,
        is_kitta_kaat: 0,
      },
      formData: new FormData(),
      zoom: 11,
      // center: L.latLng(27.649096, 84.420305),
      center: L.latLng(0, 0),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      defaultIcon: myMarkerIcon,
      marker: L.latLng(0, 0),
      geojson: null,
      fillColor: "#4eaae6",
      // options: {
      //   style(feature) {
      //     switch (feature.properties.party) {
      //       case "Republican":
      //         return { color: "#04316a" };
      //       case "Democrat":
      //         return { color: "#006fb9" };
      //       case "Dolakha2":
      //         return { color: "#000" };
      //       case "Dolakha-ward":
      //         return { color: "#3F3C3C" };
      //       default:
      //         return { color: "#228b22" };
      //     }
      //   }
      // }
      items: [],
      search: null,
      slots: [
        "body",
        "body.append",
        "body.prepend",
        "footer",
        "header.data-table-select",
        "header",
        "progress",
        "item.data-table-select",
        "item.<name>",
        "no-data",
        "no-results",
        "top",
      ],
      headers: [
        {
          text: "घरधनीको नाम",
          align: "start",
          value: "houseowner_name_np",
        },
        { text: "कित्ता नं", value: "land_plot_no" },
        { text: "Code No", value: "file_code" },
        { text: "कार्य", value: "status" },
      ],
      searchKeys: {
        houseowner_name_np: "",
        registration_date_np: "",
        internal_code: "",
        file_code: "",
        phase: "",
        desk: "",
        documents_status: "",
      },
      selectedReg: [],
      munName: "",
      noOfWards: "",
      wards: [],
    };
  },
  created() {
    this.getFiscalYear();
    this.constructionType();
    this.getDate();
    this.getMapJson();
    this.getWardNo();
  },
  mounted() {
    // const { dateField } = this.$refs;
    // if (dateField) {
    //   $(dateField.$el).nepaliDatePicker({
    //     dateFormat: "%y-%m-%d",
    //     closeOnDateSelect: true
    //   });
    //   $(dateField.$el).on("dateSelect", event => {
    //     this.registration.submitted_date_np = event.datePickerData.formattedDate;
    //   });
    // }
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#a8d8f7",
          opacity: 1,
          fillColor: fillColor,
          color: "#1c7dfc",
          fillOpacity: 0.5,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => { };
      }
      return (feature, layer) => {
        if (feature.properties.houseowner) {
          layer.bindTooltip(
            "<div>Name:" +
            feature.properties.houseowner +
            // "</div><div>nom: " +
            // feature.properties.nom +
            "</div>",
            {
              permanent: false,
              sticky: true,
            }
          );
        }
      };
    },
    getLatitude: function () {
      return this.registration.latitude;
    },
    getLongitude: function () {
      return this.registration.longitude;
    },
  },
  watch: {
    // this.changeMarker();
    getLatitude() {
      this.marker = L.latLng(this.getLatitude, this.registration.longitude);
    },
    getLongitude() {
      this.marker = L.latLng(this.registration.latitude, this.getLongitude);
    },
  },
  methods: {
    alertSuccess(message) {
      this.$swal("Added!", message, "success");
    },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Something went wrong!",
      });
    },

    // alertSuccess(){
    //         this.$swal({
    //           title: 'Are you sure?',
    //           text: "You won't be able to revert this!",
    //           type: 'warning',
    //           showCancelButton: true,
    //           confirmButtonColor: '#3085d6',
    //           cancelButtonColor: '#d33',
    //           confirmButtonText: 'Yes, delete it!'
    //         }).then((result) => {
    //           if (result.value) {
    //             this.$swal(
    //               'Deleted!',
    //               'Your file has been deleted.',
    //               'success'
    //             )
    //           }
    //         });
    //     },

    clickKittaDialog(yesClicked) {
      if (yesClicked === true) {
        this.dialogKittaKaatNaamsari = false;
        // Yo chai naamsari ko lagi sabai khali form aune banako hai guys
        if (this.registration.construction_type_id == 8) {
          this.registration.houseowner_name = "";
          this.registration.houseowner_name_np = "";
          this.registration.contact_no = "";
          this.registration.land_plot_no = "";
          this.registration.houseowner_tax_no = "";
        }

        // Yedi sansodan thiyo bhane jagga kitta number ra kardata sanket number chai reset hunchha
        if (this.registration.construction_type_id == 9) {
          // console.log(`testy: ${this.registration.houseowner_name}`);
          this.registration.houseowner_name = this.registration.houseowner_name;
          this.registration.houseowner_name_np = this.registration.houseowner_name_np;
          this.registration.contact_no = this.registration.contact_no;
          this.registration.location = this.registration.location;
          this.registration.ward_no = this.registration.ward_no;
          this.registration.houseowner_tax_no = this.registration.houseowner_tax_no;
          this.registration.land_plot_no = "";
        }
      } else if (yesClicked === false) {
        this.dialogKittaKaatNaamsari = false;

        if (this.registration.construction_type_id == 8) {
          this.registration.houseowner_name = "";
          this.registration.houseowner_name_np = "";
          this.registration.contact_no = "";
          this.registration.land_plot_no = "";
          this.registration.houseowner_tax_no = "";
        }

        // Yedi sansodan thiyo bhane jagga kitta number ra kardata sanket number chai reset hunchha
        if (this.registration.construction_type_id == 9) {
          // console.log(`testy: ${this.registration.houseowner_name}`);
          this.registration.houseowner_name = this.registration.houseowner_name;
          this.registration.houseowner_name_np = this.registration.houseowner_name_np;
          this.registration.contact_no = this.registration.contact_no;
          this.registration.location = this.registration.location;
          this.registration.ward_no = this.registration.ward_no;
          this.registration.houseowner_tax_no = this.registration.houseowner_tax_no;
          this.registration.land_plot_no = this.registration.land_plot_no;
        }
      }
    },
    async getWardNo() {
      this.isLoading = true;
      let munTemp = this.$store.getters.getMunicipality;
      try {

        if (!Object.keys(munTemp).length) {
          await this.$store.dispatch("fetchMunicipality")
        }
        let noOfWards = this.$store.getters.getMunicipality.no_of_wards;
        for (let i = 0; i < noOfWards; i++) {
          this.wards.push(this.engNumToNep(i + 1));
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("Error while retrieving municipality");
        console.log(error);
      }
    },
    getDate() {
      const dateUrl = "api/current-date";
      this.$axios
        .get(dateUrl)
        .then((response) => {
          this.isLoading = true;
          this.registration.submitted_date_np = response.data.date_np;
          this.registration.registration_date_np = response.data.date_np;
          this.registration.registration_date = response.data.date;
          this.isLoading = false;
        })
        .catch(() => { });
    },
    async getFiscalYear() {
      let fiscalYearListTemp = this.$store.getters.getFiscalYearList;
      fiscalYearListTemp.length || await this.$store.dispatch("fetchFiscalYearList");
      this.fiscalyear = this.$store.getters.getFiscalYearList;
    },
    async constructionType() {
      this.isLoading = true;
      let constructionTypeListTemp = this.$store.getters.constructionTypeList;
      try {

        if (!constructionTypeListTemp.length) {
          await this.$store.dispatch("getConstructionTypeList")
        }
        this.constructiontype = this.$store.getters.constructionTypeList;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async getMapJson() {
      let map = this.$store.getters.getMap;
      try {
        if (!Object.keys(map).length) {
          await this.$store.dispatch('fetchMap')
        }
        this.geojson = this.$store.getters.getMap;
        this.center = L.latLng(
          this.geojson.features[0].geometry.coordinates[0][0][1],
          this.geojson.features[0].geometry.coordinates[0][0][0]
        );
        this.marker = this.center;

      } catch (error) {
        console.log(`api error:${error}`);
      }
    },
    add(e) {
      // console.log(e.latlng);
      this.marker = L.latLng(e.latlng.lat, e.latlng.lng);
      this.registration.latitude = e.latlng.lat;
      this.registration.longitude = e.latlng.lng;
    },
    changeMarker() {
      this.marker = L.latLng(this.registration.latitude, this.registration.longitude);
    },
    activate() {
      setTimeout(() => (this.isHidden = false), 300);
    },
    save() {
      // start the loading spinner
      this.isLoading = true;
      const url = "api/registration";
      this.$axios
        .post(url, this.registration)
        .then((response) => {
          this.isLoading = false;
          if (response.error != null) {
            // this.message = error.response.data.message;
            this.alertError(response.data.message);
          } else {
            // this.token = response.data.access_token;
            // console.log(response.data.access_token);
            // localStorage.setItem('token', response.data.access_token);
            // console.log(localStorage.getItem("token"));
            // this.snackbar = true;
            this.alertSuccess("Successfully Added !!");
            this.$router.push({ name: "home" });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.successfulMessage = error.response.data.message;
          this.snackbar.show = true;
        });
    },
    searchFilters() {
      // const houseowner_name_np=this.houseowner_name_np;
      //  debugger;
      const statusurl = "api/registration/search";
      this.$axios.post(statusurl, this.searchKeys).then((response) => {
        // debugger;
        // this.searchKeys.houseowner_name_np = response.data.data.searchKeys;
        // console.log(this.DESSERTS);
        this.items = response.data.data;
      });
    },
    selectRegistration(reg) {
      this.selectedReg = reg;
      // for tala thap, all the details should be same
      if (this.registration.construction_type_id === 5) {
        this.registration = reg;
        this.registration.construction_type_id = 5;
        (this.registration.landowner_certificate_submitted = false),
          (this.registration.revenue_tax_slip_submitted = false),
          (this.registration.landowner_registration_certificate_submitted = true),
          (this.registration.citizenship_submitted = false),
          (this.registration.blueprint_submitted = false),
          (this.registration.designer_certificate_submitted = false),
          (this.registration.authority_letter_submitted = false),
          (this.registration.nominee_certificate_submitted = false),
          (this.registration.designer_authority_letter_submitted = false),
          (this.registration.house_map_submitted = false),
          (this.registration.houseowner_photo_submitted = false);
        this.dialogSelect = false;
      } else if (
        this.registration.construction_type_id === 8 ||
        this.registration.construction_type_id === 9
      ) {
        this.registration.is_kitta_kaat = 1;
        this.registration.latitude = reg.latitude;
        this.registration.longitude = reg.longitude;
        this.registration.ward_no = reg.ward_no;
        this.registration.location = reg.location;
        this.registration.street_no = reg.street_no;
        this.dialogNaamSari = false;
        this.dialogSelect = false;
        this.dialogKittaKaatNaamsari = true;

        if (this.registration.construction_type_id === 9) {
          this.registration.houseowner_name = reg.houseowner_name;
          this.registration.houseowner_name_np = reg.houseowner_name_np;
          this.registration.contact_no = reg.contact_no;
          this.registration.houseowner_tax_no = reg.houseowner_tax_no;
          this.registration.land_plot_no = reg.land_plot_no;
          this.registration.file_code = reg.file_code
        }
      }
      this.registration.old_reg_id = reg.id;
      console.log(this.registration);
    },
  },
};
</script>
<style scoped>
/* #styled-input {
  height: 30px;
  font-size: 20pt;
} */

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}

.v-text-field__details {
  margin: 0;
  padding: 0;
}

.map {
  width: 100%;
  height: 400px;
}

span {
  color: red;
}

.checklist-box {
  margin-top: -10px;
}

.v-dialog {
  position: absolute !important;
  bottom: 0px !important;
  right: 45px !important;
}
</style>
